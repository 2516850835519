@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #e0ecf8;
  font-family: "Raleway", sans-serif;
}

/* Scollar styles start */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; 
  background: rgba(255,255,255,0.1); 
  border-radius: 5px; */
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  box-shadow: 0px 0px 10px #ddd;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

/* scrollbar styles end */

.dropdown-menu {
  z-index: 1;
}
